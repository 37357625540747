import React, { useState, useMemo } from "react";
import { Table, Typography, Toast, Button, Space } from "@douyinfe/semi-ui";
import ModalJ from "./jianlianModal";
import {
  getJianLianData,
  postLCCDYUserApi,
  postLCCXhsUserApi,
} from "../apis/index";
import {
  dividePlatform,
  parseDYUserApiDataToUser,
  parseXHSUserApiDataToUser,
} from "../utils";
const { Paragraph } = Typography;

const DAY = 24 * 60 * 60 * 1000;

const columns = [
  {
    title: "记录id",
    dataIndex: "id",
    width: 80,
    render: (text) => text,
  },
  {
    title: "昵称",
    dataIndex: "nickname",
    width: 120,
    render: (text) => text,
  },
  {
    title: "数据平台",
    dataIndex: "tag",
    width: 60,
    render: (text) => text,
    filters: [
      {
        text: "小红书",
        value: "小红书",
      },
      {
        text: "抖音",
        value: "抖音",
      },
    ],
    onFilter: (value, record) => record.tag.includes(value),
  },
  {
    title: "链接地址",
    dataIndex: "link",
    width: 240,
    render: (text, record, index) => {
      return (
        <Paragraph
          copyable={{
            content: text,
            onCopy: () => Toast.success({ content: "复制成功" }),
          }}
        >
          {text}
        </Paragraph>
      );
    },
  },
  //   {
  //     title: "更新日期",
  //     dataIndex: "updateTime",
  //     width: 200,
  //     sorter: (a, b) => (a.updateTime - b.updateTime > 0 ? 1 : -1),
  //     render: (value) => {
  //       return value;
  //     },
  //   },
  //   {
  //     title: "",
  //     dataIndex: "operate",
  //     fixed: "right",
  //     align: "center",
  //     width: 100,
  //     render: () => {
  //       return <IconMore />;
  //     },
  //   },
];

function TableJ() {
  // about table state
  const [dataSource, setData] = React.useState([]);
  const [pickedRows, setPickedRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  // about modal state
  const [modalTableDataSource, setModalTableDataSource] = React.useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalTableLoading, setModalTableLoading] = React.useState(false);
  const [modalSubmitLoading, setModalSubmitLoading] = React.useState(false);

  const scroll = useMemo(() => ({ y: 500, x: 1200 }), []);
  const rowSelection = useMemo(
    () => ({
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
        setPickedRows(selectedRows);
      },
      getCheckboxProps: (record) => ({
        name: record.nickname,
      }),
      fixed: true,
    }),
    []
  );

  const onFetchKdocs = async () => {
    const data = await getJianLianData();
    return data;
  };

  const refreshKdocs = async () => {
    setLoading(true);
    onFetchKdocs().then((data) => {
      const nData = data.map((e) => {
        const r = dividePlatform("user", e.link);
        return {
          ...e,
          userid: r.id,
          tag: r.tag,
        };
      });
      Toast.info({
        content: "kdocs数据成功刷新",
        duration: 2,
      });
      setLoading(false);
      setData(nData);
    });
  };

  const onScratchData = async () => {
    setModalVisible(true);
    setModalTableLoading(true);
    const xhsIds = pickedRows
      .map((e) => {
        if (e.tag === "小红书") {
          return e.userid;
        } else {
          return undefined;
        }
      })
      .filter(Boolean);

    const dyIds = pickedRows
      .map((e) => {
        if (e.tag === "抖音") {
          return e.userid;
        } else {
          return undefined;
        }
      })
      .filter(Boolean);

    await Promise.all([
      postLCCXhsUserApi(xhsIds)
        .then((res) => {
          Toast.info({
            content: "小红书数据成功抓取",
            duration: 2,
          });
          return res;
        })
        .catch((err) => {
          Toast.error({
            content: "小红书数据抓取失败了",
            duration: 2,
          });
          setModalTableLoading(false);
        }),
      postLCCDYUserApi(dyIds)
        .then((res) => {
          Toast.info({
            content: "抖音数据成功抓取",
            duration: 2,
          });
          return res;
        })
        .catch((err) => {
          Toast.error({
            content: "抖音数据抓取失败了",
            duration: 2,
          });
          setModalTableLoading(false);
        }),
    ]).then(([xhsUsers, dyUsers]) => {
      console.log("big fan");
      console.log(xhsUsers, dyUsers);

      if ((dyUsers || []).length + (xhsUsers || []).length === 0) {
        setModalTableLoading(false);
        return;
      }

      const newXhsUsers = parseXHSUserApiDataToUser(dataSource, xhsUsers);
      const newDyUsers = parseDYUserApiDataToUser(dataSource, dyUsers);

      setModalTableDataSource([...newXhsUsers, ...newDyUsers]);
      setModalTableLoading(false);
    });
  };

  React.useEffect(() => {
    refreshKdocs();
  }, []);

  return (
    <>
      <Space>
        <Button onClick={refreshKdocs}>刷新kdocs数据</Button>
        <Button
          onClick={() => {
            setModalVisible(true);
          }}
        >
          打开获取数据modal
        </Button>
        <Button onClick={onScratchData} type="warning">
          探测平台数据
        </Button>
      </Space>
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={dataSource}
        rowSelection={rowSelection}
        scroll={scroll}
        loading={loading}
      />

      <ModalJ
        visible={modalVisible}
        setVisible={setModalVisible}
        data={modalTableDataSource}
        loading={modalTableLoading}
        submitLoading={modalSubmitLoading}
        setSubmitLoading={setModalSubmitLoading}
      />
    </>
  );
}

export default TableJ;
