import React from "react";
import TableJ from "../components/jianlianTable";

const Jianlian = () => {
  const handleSubmit = () => {};

  return (
    <div>
      <TableJ></TableJ>
      {/* <Form wrapperCol={{ span: 6, offset: 0 }} onValueChange={values => console.log(values)}
            onSubmit={values => handleSubmit(values)} style={{ width: 400 }}>
            {
                ({ formState, values, formApi }) => (
                    <>
                        <Form.Select field="name" label='姓名' style={{ width: 220 }}>
                            <Form.Select.Option value="方淑田">方淑田</Form.Select.Option>
                            <Form.Select.Option value="兼职1号">兼职1号</Form.Select.Option>
                            <Form.Select.Option value="兼职2号">兼职2号</Form.Select.Option>
                        </Form.Select>
                        <Form.Input field='link' label='链接' style={{}} />
                        <br />

                        <Button theme='solid' type='primary' disabled={!values.link || !values.name} htmlType='submit' >提交</Button>
          
                        <br />
                        <br />
                        <br />
                        <code style={{ marginTop: 24 }}>{JSON.stringify(formState)}</code>
                    </>
                )
            }
        </Form> */}
    </div>
  );
};

export default Jianlian;
