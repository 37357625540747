import React from "react";
import TableS from "../components/scgailanTable";

const Scgailan = () => {
  const handleSubmit = () => {};

  return (
    <div>
      <TableS></TableS>
    </div>
  );
};

export default Scgailan;
