import axios from "axios";
import dayjs from "dayjs";

// const HOST_PREFIX = "http://fabrie.goea.top";

async function getJianLianData() {
  const response = (await axios.get(`/api/kdocs/jianLianTable`)).data;

  if (response.status === "ok") {
    return (response.data || []).map((item) => {
      const [index, id, nickname, link] = item.args;
      return {
        key: id,
        index,
        id,
        nickname,
        link,
      };
    });
  } else {
    throw Error("请求失败了");
  }
}

async function getCoopAllData() {
  const response = (await axios.get(`/api/kdocs/coopAllTable`)).data;

  if (response.status === "ok") {
    return (response.data || []).map((item) => {
      const [index, id, taskId, homeAddr, contentText, contentAddr] = item.args;
      return {
        key: id,
        index,
        id,
        taskId,
        homeAddr,
        contentText,
        contentAddr,
      };
    });
  } else {
    throw Error("请求失败了");
  }
}

async function postLCCXhsUserApi(ids) {
  const response = (
    await axios(`/api/lcc/xhsUserApi`, {
      method: "POST",
      data: { ids },
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).data;

  if (response.status === "ok") {
    return response.data || [];
  } else {
    throw Error("请求失败了");
  }
}

async function postLCCDYUserApi(ids) {
  const response = (
    await axios(`/api/lcc/dyUserApi`, {
      method: "POST",
      data: { ids },
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).data;

  if (response.status === "ok") {
    return response.data || [];
  } else {
    throw Error("请求失败了");
  }
}

async function postLCCXhsNoteApi(ids) {
  const response = (
    await axios(`/api/lcc/xhsNoteApi`, {
      method: "POST",
      data: { ids },
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).data;

  if (response.status === "ok") {
    return response.data || [];
  } else {
    throw Error("请求失败了");
  }
}

async function postLCCDyVedioApi(ids) {
  const response = (
    await axios(`/api/lcc/dyVedioApi`, {
      method: "POST",
      data: { ids },
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).data;

  if (response.status === "ok") {
    return response.data || [];
  } else {
    throw Error("请求失败了");
  }
}

async function updateJianLianTable(records) {
  const response = (
    await axios(`/api/kdocs/updateJianLianTable`, {
      method: "POST",
      data: { records },
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).data;

  if (response.status === "ok") {
    return response.data;
  } else {
    throw Error("请求失败了");
  }
}

async function updateCoopAllTable(records) {
  const response = (
    await axios(`/api/kdocs/updateCoopAllTable`, {
      method: "POST",
      data: { records },
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).data;

  if (response.status === "ok") {
    return response.data;
  } else {
    throw Error("请求失败了");
  }
}

async function getScGailanBookmarkEvent(dashboardCode, bookmarkId, payload) {
  const response = (
    await axios(`/api/shence/scBookmarkReport`, {
      method: "POST",
      data: {
        dashboardCode,
        bookmarkId,
        payload,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).data;

  if (response.status === "ok") {
    // console.log(response.body, response.data);

    let rollup_list = response.body.measures?.map((measure, index) => {
      let totalNumber = null;
      try {
        totalNumber = response.data.rollup_result.rows[0]?.values[0][index];
      } catch (err) {
        console.error(err);
      }

      return {
        id: index,
        indicators: measure.name,
        totalNumber,
        measure,
      };
    });

    let detail_list = response.body.measures?.map((measure, mIndex) => {
      let obj = {
        id: mIndex,
        indicators: measure.name,
        measure,
      };
      const otherTitles = response.data.detail_result.series.map(
        (serie, sIndex) => {
          const key = dayjs(serie).format("YYYY-MM-DD");
          obj[key] =
            response.data.detail_result.rows[0]?.values[sIndex][mIndex] || "0";
          return key;
        }
      );

      return obj;
    });

    let detail_list2 = response.data.detail_result.rows?.map((row, rIndex) => {
      let obj = {
        id: rIndex,
        indicators: row.by_values[0],
        row,
      };

      response.data.detail_result.series.map((serie, sIndex) => {
        const key = dayjs(serie).format("YYYY-MM-DD");
        obj[key] =
          response.data.detail_result.rows[rIndex]?.values[sIndex][0] || "0";
        return key;
      });

      return obj;
    });

    const if2 = (response.body.by_fields || [])?.length > 0;

    return {
      body: response.body,
      detail_result: {
        series: response.data.detail_result.series,
        info: {
          from_date: response.body.from_date,
          to_date: response.body.to_date,
          dashboard_id: response.body.dashboard_id,
          bookmarkid: response.body.bookmarkid,
        },
        list: if2 ? detail_list2 : detail_list,
      },
      rollup_result: {
        info: {
          from_date: response.body.from_date,
          to_date: response.body.to_date,
          dashboard_id: response.body.dashboard_id,
          bookmarkid: response.body.bookmarkid,
        },
        list: rollup_list,
      },
    };
  } else {
    throw Error("请求失败了");
  }
}

async function getScDashboardById(dashboardCode) {
  const response = (
    await axios(`/api/shence/scDashboard`, {
      method: "GET",
      params: { dashboardCode },
    })
  ).data;

  if (response.status === "ok") {
    return {
      data: response.data,
    };
  } else {
    throw Error("请求失败了");
  }
}

export {
  getJianLianData,
  postLCCXhsUserApi,
  postLCCDYUserApi,
  postLCCXhsNoteApi,
  postLCCDyVedioApi,
  updateJianLianTable,
  updateCoopAllTable,
  getCoopAllData,
  getScGailanBookmarkEvent,
  getScDashboardById,
};
