import React from "react";
import TableC from "../components/coopallTable";

const Coopall = () => {
  const handleSubmit = () => {};

  return (
    <div>
      <TableC></TableC>
    </div>
  );
};

export default Coopall;
