import React from "react";
import {
  Modal,
  Table,
  Typography,
  Toast,
  Button,
  Space,
} from "@douyinfe/semi-ui";
import { updateCoopAllTable, updateJianLianTable } from "../apis";
const { Paragraph } = Typography;

// collected_count: note.collected_count,
// comments_count: note.comments_count,
// liked_count: note.liked_count,
// view_count: note.view_count,

const columns = [
  {
    title: "任务号",
    dataIndex: "taskId",
    fixed: true,
    width: 80,
    render: (text) => String(text).padStart(6, "0"),
  },
  {
    title: "平台",
    dataIndex: "tag",
    fixed: true,
    width: 80,
    render: (text) => {
      return text;
    },
  },
  {
    title: "❤️阅读数（至少72h后）此数据不准",
    dataIndex: "view_count",
    width: 100,
    render: (text) => {
      return text;
    },
  },
  {
    title: "❤️总点赞数（至少72h后）",
    dataIndex: "liked_count",
    width: 100,
    render: (text) => {
      return text;
    },
  },
  {
    title: "❤️总收藏数（至少72h后）",
    dataIndex: "collected_count",
    width: 100,
    render: (text) => {
      return text;
    },
  },
];

const ModalC = (props) => {
  const {
    data,
    visible,
    setVisible,
    loading,
    submitLoading,
    setSubmitLoading,
  } = props;
  const [pickedRows, setPickedRows] = React.useState([]);

  const rowSelection = React.useMemo(
    () => ({
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
        setPickedRows(selectedRows);
      },
      getCheckboxProps: (record) => ({
        name: record.name,
      }),
      fixed: true,
    }),
    []
  );

  const handleOK = async () => {
    setSubmitLoading(true);
    await updateCoopAllTable(pickedRows);
    Toast.info({
      content: "kdocs数据成功更新",
      duration: 2,
    });
    setSubmitLoading(false);
    setVisible(false);
  };

  return (
    <>
      <Modal
        width={"70%"}
        title="获取数据如下"
        visible={visible}
        maskClosable={false}
        onCancel={() => {
          setVisible(false);
        }}
        footer={
          <Space>
            <Button
              onClick={() => {
                setVisible(false);
              }}
            >
              关闭窗口
            </Button>
            <Button
              loading={submitLoading}
              onClick={() => {
                handleOK();
              }}
              type="warning"
            >
              将数据更新到金山文档
            </Button>
          </Space>
        }
      >
        <Table
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          loading={loading}
        />
      </Modal>
    </>
  );
};

export default ModalC;
