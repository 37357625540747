import React, { useMemo } from "react";
import { Table, Typography, Toast, Button, Space } from "@douyinfe/semi-ui";
import {
  getCoopAllData,
  postLCCDyVedioApi,
  postLCCXhsNoteApi,
} from "../apis/index";
import ModalC from "./coopallModal";
import {
  dividePlatform,
  parseDYVedioApiDataToNote,
  parseXHSNoteApiDataToNote,
  parseXHSUserApiDataToUser,
} from "../utils";
const { Paragraph } = Typography;

const DAY = 24 * 60 * 60 * 1000;

const columns = [
  {
    title: "平台",
    dataIndex: "tag",
    width: 80,
    render: (text) => text,
  },
  {
    title: "任务号",
    dataIndex: "taskId",
    fixed: true,
    width: 120,
    render: (text) => String(text).padStart(6, "0"),
  },
  {
    title: "主页地址",
    dataIndex: "homeAddr",
    width: 240,
    render: (text, record, index) => {
      return (
        <Paragraph
          copyable={{
            content: text,
            onCopy: () => Toast.success({ content: "复制成功" }),
          }}
        >
          {text}
        </Paragraph>
      );
    },
  },
  {
    title: "内容地址",
    dataIndex: "contentText",
    width: 240,
    render: (text, record) => {
      return (
        <Paragraph
          copyable={{
            content: record.contentAddr,
            onCopy: () => Toast.success({ content: "复制成功" }),
          }}
        >
          {text}
        </Paragraph>
      );
    },
  },
];

function TableC() {
  // about table state
  const [dataSource, setData] = React.useState([]);
  const [pickedRows, setPickedRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  // about modal state
  const [modalTableDataSource, setModalTableDataSource] = React.useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalTableLoading, setModalTableLoading] = React.useState(false);
  const [modalSubmitLoading, setModalSubmitLoading] = React.useState(false);

  const scroll = useMemo(() => ({ y: 500, x: 1200 }), []);
  const rowSelection = useMemo(
    () => ({
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
        setPickedRows(selectedRows);
      },
      getCheckboxProps: (record) => ({
        name: record.taskId,
      }),
      fixed: true,
    }),
    []
  );

  const onFetchKdocs = async () => {
    const data = await getCoopAllData();
    return data;
  };

  const refreshKdocs = async () => {
    setLoading(true);
    onFetchKdocs().then((data) => {
      const nData = data.map((e) => {
        const r = dividePlatform("note", e.contentAddr);
        return {
          ...e,
          noteid: r.id,
          tag: r.tag,
        };
      });
      console.log(
        `- data source of coooall `,
        data.slice(0, 60),
        nData.slice(0, 60)
      );
      Toast.info({
        content: "kdocs数据成功刷新",
        duration: 2,
      });
      setLoading(false);
      setData(nData);
    });
  };

  const onScratchData = async () => {
    setModalVisible(true);
    setModalTableLoading(true);
    const xhsIds = pickedRows
      .map((e) => {
        if (e.tag === "小红书") {
          return e.noteid;
        } else {
          return undefined;
        }
      })
      .filter(Boolean);

    const dyIds = pickedRows
      .map((e) => {
        if (e.tag === "抖音") {
          return e.noteid;
        } else {
          return undefined;
        }
      })
      .filter(Boolean);

    await Promise.all([
      postLCCXhsNoteApi(xhsIds)
        .then((res) => {
          // Toast.info({
          //   content: "小红书数据成功抓取",
          //   duration: 2,
          // });
          return res;
        })
        .catch((err) => {
          Toast.error({
            content: "小红书数据抓取失败了",
            duration: 2,
          });
          setModalTableLoading(false);
        }),
      postLCCDyVedioApi(dyIds)
        .then((res) => {
          // Toast.info({
          //   content: "抖音数据成功抓取",
          //   duration: 2,
          // });
          return res;
        })
        .catch((err) => {
          Toast.error({
            content: "抖音数据抓取失败了",
            duration: 2,
          });
          setModalTableLoading(false);
        }),
    ]).then(([xhsNotes, dyVedios]) => {
      if ((xhsNotes || []).length + (dyVedios || []).length === 0) {
        setModalTableLoading(false);
        return;
      }

      const newXhsNotes = parseXHSNoteApiDataToNote(dataSource, xhsNotes);
      const newDyVedios = parseDYVedioApiDataToNote(dataSource, dyVedios);

      console.log(`- newDyVedios `, newDyVedios);

      setModalTableDataSource([...newXhsNotes, ...newDyVedios]);
      setModalTableLoading(false);
    });
  };

  React.useEffect(() => {
    refreshKdocs();
  }, []);

  return (
    <>
      <Space>
        <Button onClick={refreshKdocs}>刷新kdocs数据</Button>
        <Button
          onClick={() => {
            setModalVisible(true);
          }}
        >
          打开获取数据modal
        </Button>
        <Button onClick={onScratchData} type="warning">
          探测平台数据
        </Button>
      </Space>
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={dataSource}
        rowSelection={rowSelection}
        scroll={scroll}
        loading={loading}
      />

      <ModalC
        visible={modalVisible}
        setVisible={setModalVisible}
        data={modalTableDataSource}
        loading={modalTableLoading}
        submitLoading={modalSubmitLoading}
        setSubmitLoading={setModalSubmitLoading}
      />
    </>
  );
}

export default TableC;
