import React from "react";
import {
  Toast,
  Button,
  Space,
  Input,
  Modal,
  Typography,
  Select,
  Switch,
  Form,
} from "@douyinfe/semi-ui";
import { getScDashboardById } from "../apis/index";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import BookMarkTableComp from "./scbookmarkTable";
import { useSearchParams } from "react-router-dom";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title } = Typography;

function formatData(data, config, unit = "day", from_date, to_date) {
  let jsonData = JSON.parse(data);
  let jsonConfig = JSON.parse(config);

  jsonData = {
    ...jsonData,
    measures: jsonData.measures.filter(
      (_, idx) => jsonConfig.measureIndexs.indexOf(idx) > -1
    ),
    detail_and_rollup: true,
    use_cache: false,
    from_date,
    to_date,
    unit,

    compareKey: undefined,
    compare_from_date: undefined,
    compare_to_date: undefined,
  };

  return jsonData;
}

function TableS() {
  // about table state
  const [dataSource, setData] = React.useState({ name: "", items: [] });
  const [rangeDates, setRangeDates] = React.useState([
    dayjs().startOf("month"),
    dayjs(),
  ]);
  const [unit, setUnit] = React.useState("month");
  const [showTotal, setShowTotal] = React.useState(false);
  const [options, setOptions] = React.useState([
    {
      value: "day",
      label: "按日查看",
    },
    {
      value: "week",
      label: "按周查看",
    },
    {
      value: "month",
      label: "按月查看",
    },
  ]);

  const [loading, setLoading] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // about modal state
  const [modalVisible, setModalVisible] = React.useState(false);
  const [savingDashboardIdLoading, setSavingDashboardIdLoading] =
    React.useState(false);
  const [dashboardCode, setDashboardCode] = React.useState(
    searchParams.get("key")
  );

  const onFetchScDashboard = async () => {
    const { data } = await getScDashboardById(dashboardCode);
    return data;
  };

  const refreshShenCe = async () => {
    setLoading(true);
    onFetchScDashboard().then((data) => {
      Toast.info({
        content: "数据成功刷新",
        duration: 2,
      });
      setLoading(false);
      setData(data);
    });
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setRangeDates([dayjs(dateStrings[0]), dayjs(dateStrings[1])]);
    } else {
      console.log("Clear");
    }
  };

  const onUnitChange = (x) => {
    setUnit(x);
  };

  const onShowTotalChange = (x) => {
    setShowTotal(x);
  };

  const rangePresets = [
    { label: "今日", value: [dayjs(), dayjs()] },
    { label: "本周", value: [dayjs().startOf("week"), dayjs()] },
    { label: "本月", value: [dayjs().startOf("month"), dayjs()] },
    { label: "本年", value: [dayjs().startOf("year"), dayjs()] },
    {
      label: "上线至今",
      value: [dayjs(dataSource.create_time), dayjs()],
    },
  ];

  const handleOK = () => {
    searchParams.set("key", dashboardCode);
    setSearchParams(searchParams);
    refreshShenCe();

    setTimeout(() => {
      setModalVisible(false);
    }, 500);
  };

  const onChangeDashboardId = (x) => {
    setDashboardCode(x);
  };

  React.useEffect(() => {
    if (!dashboardCode) {
      setModalVisible(true);
    } else {
      refreshShenCe();
    }
  }, []);

  return (
    <>
      <Space>
        {/* <Button onClick={refreshShenCe}>刷新神策数据</Button> */}
        {/* <Button
          onClick={() => {
            console.log(rangeDates, unit);
            setModalVisible(true);
          }}
        >
          修改秘钥
        </Button> */}
      </Space>

      <Title heading={2} style={{ margin: "-24px 0 12px" }}>
        {dataSource.name}
      </Title>
      <Space>
        {/* <Switch
          label={"总计"}
          value={showTotal}
          onChange={onShowTotalChange}
        ></Switch> */}

        <Select
          filter
          placeholder="单位"
          onChange={onUnitChange}
          defaultValue={"total"}
          value={unit}
        >
          {options.map((option) => (
            <Option value={option.value} key={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        <RangePicker
          presets={rangePresets}
          onChange={onRangeChange}
          value={rangeDates}
        />
      </Space>
      <br />
      <br />
      {React.useMemo(() => {
        return (dataSource?.items || []).map(({ bookmark }) => {
          const payload = formatData(
            bookmark.data,
            bookmark.config,
            unit,
            rangeDates[0].format("YYYY-MM-DD"),
            rangeDates[1].format("YYYY-MM-DD")
          );
          return (
            <BookMarkTableComp
              key={bookmark.id}
              dashboardCode={dashboardCode}
              showTotal={showTotal}
              unit={unit}
              {...bookmark}
              payload={payload}
            />
          );
        });
      }, [
        JSON.stringify(dataSource.items),
        unit,
        rangeDates[0].format("YYYY-MM-DD"),
        rangeDates[1].format("YYYY-MM-DD"),
      ])}

      <Modal
        width={"40%"}
        title="填写访问密码"
        visible={modalVisible}
        maskClosable={false}
        onCancel={() => {
          setModalVisible(false);
        }}
        footer={
          <Space>
            <Button
              loading={savingDashboardIdLoading}
              onClick={() => {
                handleOK();
              }}
              type="primary"
            >
              进入
            </Button>
          </Space>
        }
      >
        <Input
          placeholder="输入密码（联系Ada获取）"
          value={dashboardCode}
          onChange={onChangeDashboardId}
        ></Input>
      </Modal>
    </>
  );
}

export default TableS;
