// https://www.douyin.com/user/MS4wLjABAAAA5LpDBIwi8UM59jP-IdTi0CBhnUd1SE8QD7UbtZzJZtc?previous_page=app_code_link
//  https://v.douyin.com/ieybyYE1/
import axios from "axios";

// TODO
// async function getRedirectUrl (link) {

// }

function dividePlatform(type, link) {
  if (type !== "note" && type !== "user") {
    return { tag: "错误的type类型" };
  }
  try {
    new URL(link);
  } catch (e) {
    return { tag: "错误的link" };
  }

  const tags = {
    note: {
      xhs: "explore",
    },
    user: {
      xhs: "profile",
      dy: "user",
    },
  };

  if (
    link.indexOf("xiaohongshu") > -1 &&
    link.indexOf(tags[type]["xhs"]) > -1
  ) {
    const newUrl = new URL(link).pathname;
    const arr = newUrl.split("/");
    const preIndex = arr.indexOf(tags[type]["xhs"]);
    const id = arr[preIndex + 1];
    return { tag: "小红书", id };
  } else if (link.indexOf("douyin") > -1) {
    const newUrl = new URL(link);
    let id;
    if (type === "user") {
      const arr = newUrl.pathname.split("/");
      const preIndex = arr.indexOf(tags[type]["dy"]);
      id = arr[preIndex + 1];
    } else if (type === "note") {
      id = newUrl.searchParams.get("modal_id");
    }

    return { tag: "抖音", id };
  } else if (link.indexOf("bilibili") > -1) {
    const newUrl = new URL(link).pathname;
    const arr = newUrl.split("/");
    const id = arr[arr.length - 1];
    return { tag: "B站", id };
  } else {
    return { tag: "未知" };
  }
}

// res.code === 200
//         ? { fans: res.data.fans, desc: res.data.desc, userid: res.data.userid }
//         : undefined
function parseXHSUserApiDataToUser(originData, users) {
  const newUsers = users
    .map((res) => {
      if (res.code === 200) {
        const u = originData.find((d) => d.userid === res.data.userid);

        return {
          ...u,
          fans: res.data.fans,
          desc: res.data.desc,
          userid: res.data.userid,
        };
      } else {
        return undefined;
      }
    })
    .filter(Boolean);

  return newUsers;
}

function parseDYUserApiDataToUser(originData, users) {
  const newUsers = users
    .map((res) => {
      if (res.code === 200) {
        const u = originData.find((d) => d.userid === res.data.sec_uid);

        return {
          ...u,
          fans: res.data.follower_count,
          desc: res.data.signature,
          userid: res.data.sec_uid,
          nickname: res.data.nickname,
        };
      } else {
        return undefined;
      }
    })
    .filter(Boolean);

  return newUsers;
}

function parseXHSNoteApiDataToNote(originData, notes) {
  const newNotes = notes
    .map((res) => {
      if (res.code === 200 && res.data.code === 0) {
        let note = {};
        const u = originData.find((d) => {
          return (
            res.data.data.findIndex((x) => {
              if (x.note_list[0]?.id === d.noteid) {
                note = x.note_list[0];
                return true;
              }
            }) > -1
          );
        });
        return {
          ...u,
          collected_count: note.collected_count,
          comments_count: note.comments_count,
          liked_count: note.liked_count,
          view_count: note.view_count,
        };
      } else {
        return undefined;
      }
    })
    .filter(Boolean);

  return newNotes;
}

function parseDYVedioApiDataToNote(originData, vedios) {
  const newNotes = vedios
    .map((res) => {
      if (res.code === 200) {
        let statistics = res.data.statistics;
        const u = originData.find((d) => res.data.aweme_id === d.noteid);
        return {
          ...u,
          liked_count: statistics.digg_count,
          comments_count: statistics.comment_count,
          share_count: statistics.share_count,
          collected_count: statistics.collect_count,
          view_count: statistics.play_count,
        };
      } else {
        return undefined;
      }
    })
    .filter(Boolean);

  return newNotes;
}

export {
  dividePlatform,
  parseXHSUserApiDataToUser,
  parseDYUserApiDataToUser,
  parseXHSNoteApiDataToNote,
  parseDYVedioApiDataToNote,
};
