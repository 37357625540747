import React from "react";
import {
  useNavigate,
  Outlet,
  useLocation,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { Layout, Nav, Button, Modal, Space } from "@douyinfe/semi-ui";
import {
  IconBytedanceLogo,
  IconExit,
  IconHistogram,
  IconHome,
  IconIndentLeft,
  IconIndentRight,
  IconSemiLogo,
  IconSetting,
} from "@douyinfe/semi-icons";
// import { Menu, generateMenus, findMenuByPathname, generateCrumbs, getMenu } from '@/utils/router'

const SECREAT_KEYS = {
  winnie: "1", // admin
  z8IXWHQ3te: "70", // 多元素
  m3UDCsZHte: "149", // 连媒介
  Zmyrz4rAnh: "242", // 啊听
};

export default function Index() {
  const [collapsed, setCollapsed] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [selectedKeys, setSelectedKeys] = React.useState([]);
  const { Header, Footer, Sider, Content } = Layout;

  const onSelect = (data) => {
    setSelectedKeys([...data.selectedKeys]);
    navigate({ pathname: data.selectedItems[0].path, search: location.search });
  };

  // const crumbs = React.useMemo(() => {
  //     const menu = getMenu(menus, currentKey)
  //     return menu ? generateCrumbs(menu) : []
  // }, [currentKey, menus])

  function handleLogout() {
    Modal.confirm({
      title: "确认",
      content: "确认退出吗？",
      onOk: () => {},
    });
  }

  function getItems() {
    const key = searchParams.get("key");
    if (SECREAT_KEYS[key] === "1") {
      return [
        {
          itemKey: "jianlian",
          text: "建联录入",
          icon: <IconHome size="large" />,
          path: "/jianlian",
        },
        {
          itemKey: "coopall",
          text: "内容合作总表",
          icon: <IconHistogram size="large" />,
          path: "/coopall",
        },
        // {
        //   itemKey: "scgailan",
        //   text: "神策概览",
        //   icon: <IconHistogram size="large" />,
        //   path: "/scgailan",
        // },
        {
          itemKey: "setting",
          text: "设置",
          icon: <IconSetting size="large" />,
          path: "/setting",
        },
      ];
    } else if (SECREAT_KEYS[key]) {
      return [
        {
          itemKey: "scgailan",
          text: "查看数据",
          icon: <IconHistogram size="large" />,
          path: "/scgailan",
        },
      ];
    } else {
      return [];
    }
  }

  return (
    <Layout style={{ border: "1px solid var(--semi-color-border)" }}>
      <Sider style={{ backgroundColor: "var(--semi-color-bg-1)" }}>
        <Nav
          selectedKeys={selectedKeys}
          style={{ maxWidth: 220, height: "100%" }}
          items={getItems()}
          onSelect={onSelect}
          header={{
            logo: <IconSemiLogo style={{ fontSize: 36 }} />,
            text: "A Dog",
            onClick: () => {
              setSelectedKeys([]);
              navigate("/");
            },
          }}
          isCollapsed={collapsed}
        />
      </Sider>
      <Layout>
        <Header style={{ backgroundColor: "var(--semi-color-bg-1)" }}>
          <Nav
            mode="horizontal"
            header={
              <>
                <Button
                  onClick={() => {
                    setCollapsed(!collapsed);
                  }}
                  theme="borderless"
                  style={{
                    color: "var(--semi-color-text-2)",
                    marginRight: "12px",
                  }}
                  icon={collapsed ? <IconIndentRight /> : <IconIndentLeft />}
                />
              </>
            }
            footer={
              <Space>
                {/* <Button
                                    theme="borderless"
                                    icon={<IconRefresh size="large" />}
                                    style={{
                                        color: 'var(--semi-color-text-2)',
                                    }}
                                    onClick={() => { }}
                                />
                                <Button
                                    theme="borderless"
                                    icon={<IconExit size="large" />}
                                    style={{
                                        color: 'var(--semi-color-text-2)',
                                    }}
                                    onClick={handleLogout}
                                /> */}
              </Space>
            }
          ></Nav>
        </Header>
        <Content
          style={{
            padding: "24px",
            minHeight: "calc(100vh - 72px)",
            backgroundColor: "var(--semi-color-bg-1)",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
