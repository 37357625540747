import "./App.css";
import AppLayout from "./layout/Layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Jianlian from "./pages/jianlian";
import Coopall from "./pages/coopall";
import Scgailan from "./pages/scgailan";

function App() {
  return (
    <div className="App">
      <Router basename="/app">
        <Routes>
          <Route path="/" element={<AppLayout></AppLayout>}>
            <Route
              index
              path="jianlian"
              element={<Jianlian></Jianlian>}
            ></Route>
            <Route path="coopall" element={<Coopall></Coopall>}></Route>
            <Route path="scgailan" element={<Scgailan></Scgailan>}></Route>
            <Route path="setting" element={<div>setting</div>}></Route>
            <Route path="/" element={<div>Home</div>}></Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
