import React, { useCallback } from "react";
import { Table, Toast, Button, Space } from "@douyinfe/semi-ui";
import { getScGailanBookmarkEvent } from "../apis/index";
import { Typography } from "@douyinfe/semi-ui";
import dayjs from "dayjs";
const { Title } = Typography;

function BookMarkTableComp({
  dashboardCode,
  id,
  name,
  payload,
  showTotal,
  unit,
}) {
  // about table state
  const [dataSource, setData] = React.useState([]);
  const [detailResult, setDetailResult] = React.useState({
    series: [],
    list: [],
    info: {},
  });
  const [rollupResult, setRollupResult] = React.useState({
    list: [],
    info: {},
  });
  const [requestBody, setRequestBody] = React.useState({});
  const [crabInfo, setCrabInfo] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const onFetchScGailanData = React.useCallback(
    async (payload) => {
      const { detail_result, rollup_result, body } =
        await getScGailanBookmarkEvent(dashboardCode, id, payload);
      setDetailResult(detail_result);
      setRollupResult(rollup_result);
      setRequestBody(body);
      return {
        detail_result,
        rollup_result,
      };
    },
    [dashboardCode, id, payload]
  );

  const refreshShenCe = (payload) => {
    setLoading(true);
    onFetchScGailanData(payload).then(({ detail_result, rollup_result }) => {
      // Toast.info({
      //   content: `bookmark-${id}成功刷新`,
      //   duration: 2,
      // });
      setLoading(false);
      console.log({ detail_result, rollup_result });
      if (showTotal) {
        setData(rollup_result.list);
        setCrabInfo(rollup_result.info);
      } else {
        setData(detail_result.list);
        setCrabInfo(detail_result.info);
      }
    });
  };

  const getColumns = () => {
    const columns = [
      {
        title: "指标",
        dataIndex: "indicators",
        width: 360,
        render: (text) => text,
      },
    ];

    if (showTotal) {
      columns.push({
        title: "合计",
        dataIndex: "totalNumber",
        width: 240,
        render: (text) => text,
      });
    } else {
      detailResult.series.map((serie, sIndex) => {
        const key = dayjs(serie).format("YYYY-MM-DD");

        const getTitle = (text) => {
          text = dayjs(text).format("MM-DD");
          if (unit === "day") {
            return text;
          } else if (unit === "week") {
            return text + "当周";
          } else if (unit === "month") {
            text = dayjs(text).format("YY-MM");
            return text + "当月";
          }
        };

        columns.push({
          title: getTitle(key),
          dataIndex: key,
          width: 100,
          render: (text) => text,
        });
      });
    }

    return columns;
  };

  React.useEffect(() => {
    refreshShenCe(JSON.stringify(payload));
  }, [JSON.stringify(payload)]);

  React.useEffect(() => {
    console.log(showTotal);
    if (showTotal) {
      setData(rollupResult.list);
      setCrabInfo(rollupResult.info);
    } else {
      setData(detailResult.list);
      setCrabInfo(detailResult.info);
    }
  }, [showTotal]);

  const curColumns = getColumns();
  console.log(`- curColumns `, showTotal, curColumns, dataSource);
  return (
    <>
      <Title heading={5} style={{ margin: "8px 0" }}>
        {name}
      </Title>
      <Table columns={curColumns} dataSource={dataSource} loading={loading} />
    </>
  );
}

export default BookMarkTableComp;
