import React from "react";
import {
  Modal,
  Table,
  Typography,
  Toast,
  Button,
  Space,
} from "@douyinfe/semi-ui";
import { updateJianLianTable } from "../apis";
const { Paragraph } = Typography;

const columns = [
  {
    title: "昵称",
    dataIndex: "nickname",
    fixed: true,
    width: 80,
    render: (text, record, index) => {
      return text;
    },
  },
  {
    title: "链接地址",
    dataIndex: "link",
    width: 100,
    render: (text, record, index) => {
      return (
        <Paragraph
          copyable={{
            content: text,
            onCopy: () => Toast.success({ content: "复制成功" }),
          }}
        >
          {text}
        </Paragraph>
      );
    },
  },
  {
    title: "粉丝数量",
    dataIndex: "fans",
    width: 80,
    render: (text, record, index) => {
      return text;
    },
  },
  {
    title: "简介",
    dataIndex: "desc",
    width: 300,
    render: (text, record, index) => {
      return text;
    },
  },
];

const ModalJ = (props) => {
  const {
    data,
    visible,
    setVisible,
    loading,
    submitLoading,
    setSubmitLoading,
  } = props;
  const [pickedRows, setPickedRows] = React.useState([]);

  const rowSelection = React.useMemo(
    () => ({
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
        setPickedRows(selectedRows);
      },
      getCheckboxProps: (record) => ({
        name: record.name,
      }),
      fixed: true,
    }),
    []
  );

  const handleOK = async () => {
    setSubmitLoading(true);
    await updateJianLianTable(pickedRows);
    Toast.info({
      content: "kdocs数据成功更新",
      duration: 2,
    });
    setSubmitLoading(false);
    setVisible(false);
  };

  return (
    <>
      <Modal
        width={"70%"}
        title="获取数据如下"
        visible={visible}
        maskClosable={false}
        onCancel={() => {
          setVisible(false);
        }}
        footer={
          <Space>
            <Button
              onClick={() => {
                setVisible(false);
              }}
            >
              关闭窗口
            </Button>
            <Button
              loading={submitLoading}
              onClick={() => {
                handleOK();
              }}
              type="warning"
            >
              将数据更新到金山文档
            </Button>
          </Space>
        }
      >
        <Table
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          loading={loading}
        />
      </Modal>
    </>
  );
};

export default ModalJ;
